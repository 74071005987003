import axios from 'axios';

export default class HttpService {
  constructor(urlBase) {
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },

      baseURL: urlBase,
    });

    this.service = service;
  }

  get(path) {
    return this.service.get(path);
  }

  post(path, body) {
    return this.service.post(path, body);
  }

  put(path, body) {
    return this.service.put(path, body);
  }

  delete(path, body) {
    return this.service.delete(path, body);
  }
}
