import HttpService from './HttpService';

export default class PetshopService {
  constructor() {
    this.api = new HttpService(process.env.GATSBY_API_IPETIS);
  }

  postPetshop(body) {
    const url = `/petshop`;

    return this.api.post(url, body);
  }

  checkEmail(body) {
    const url = `/checkemail`;

    return this.api.post(url, body);
  }

  checkDocument(body) {
    const url = `/checkdocument`;

    return this.api.post(url, body);
  }

  postEmailLead(body) {
    const url = `/emaillead`;

    return this.api.post(url, body);
  }

  async session(email, password) {
    const url = '/session';
    return this.api.post(url, {email, password})
  }
}
